<template>
  <v-list-item
    v-if="currentPlan"
    two-line
    class="border-top border-bottom pt-2"
    @click="$router.push({ name: 'BillingPlans' })"
  >
    <v-list-item-content>
      <v-list-item-subtitle class="text-uppercase caption">
        Plano atual
      </v-list-item-subtitle>
      <v-list-item-title class="text-subtitle-2 my-2">
        <span class="mr-2">
          {{ planName }} para
          {{ `${usersNumber} ${usersNumber === 1 ? "usuário" : "usuários"}` }}
        </span>
        <v-chip :color="statusColor[planStatus]" x-small>
          {{ status[planStatus] }}
        </v-chip>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon v-text="'ph-caret-right'" />
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { statusColor } from "@/helpers/variables/colors";
import { status } from "@/helpers/variables/translateString.js";
import { mapGetters } from "vuex";

export default {
  name: "CurrentPlanBanner",

  data() {
    return { statusColor, STATUS, status };
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "clientActivePlans",
      "usersNumber",
      "activePlansNumber",
      "currentPlan",
    ]),

    planName() {
      return this.currentPlan ? this.currentPlan.plan.name : "";
    },

    planStatus() {
      if (this.currentPlan) {
        if (this.currentPlan.status === STATUS.TRIAL) {
          return this.currentPlan.valid_status === STATUS.TRIAL_EXPIRED
            ? "EXPIRED"
            : "TRIAL";
        } else {
          return this.currentPlan.status;
        }
      }
      return "DEFAULT";
    },
  },

  hasActivePlans() {
    return this.activePlansNumber > 0;
  },
};
</script>
