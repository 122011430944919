<template>
  <v-navigation-drawer
    v-model="showSidebar"
    color="primarySuite"
    :width="rail ? sidebarSmall : sidebarExpanded"
    class="pa-2 pt-0"
    app
  >
    <v-toolbar color="primarySuite" flat dark>
      <v-app-bar-nav-icon
        :class="{ 'rotate-icon': rail }"
        @click="toggleMenu"
      ></v-app-bar-nav-icon>

      <v-toolbar-title @click="goToindexPath()" class="px-5 link">
        <v-img
          max-width="170"
          src="@/assets/appslogos/full/ConectaSuiteBrancoFull.png"
        />
      </v-toolbar-title>
    </v-toolbar>
    <div
      @mouseover="rail ? ((sidebarSmall = '300'), (isExpanded = true)) : ''"
      @mouseleave="rail ? ((sidebarSmall = '72'), (isExpanded = false)) : ''"
      :class="`list-container ${rail ? 'hide-scroll' : ''}`"
    >
      <v-list nav dense dark>
        <div v-for="group in menu" :key="group.title">
          <v-subheader
            v-if="isExpanded && group.title"
            class="mt-3 text-caption font-weight-bold accentSuite--text"
            >{{ group.title }}</v-subheader
          >
          <div
            v-show="!item.hidden"
            v-for="item in group.menus"
            :key="item.title"
          >
            <v-list-group
              :sub-group="item.submenu.submenu"
              :no-action="item.submenu.submenu"
              v-if="item.submenu"
              :value="item.value"
              :class="`${
                (isActiveGroup(item) || isHiddenPage(item)) && !item.value
                  ? 'active-group'
                  : ''
              } mx-0 pa-0 my-1`"
              color="#DDC9FF"
            >
              <template v-slot:activator>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-icon style="max-width: 24px">
                    <v-icon
                      v-if="item.icon"
                      slot="prependIcon"
                      v-text="item.icon"
                      class="mt-2"
                    />
                    <img
                      v-if="item.logo"
                      slot="prependIcon"
                      :src="item.logo"
                      height="24"
                      class="mt-1"
                    />
                  </v-list-item-icon>
                  <v-list-item-content :class="`py-4`">
                    <v-list-item-title v-text="item.title" />
                  </v-list-item-content>
                </v-list-item>
              </template>

              <div v-for="(subItem, i) in item.submenu" :key="i">
                <v-list-item
                  v-show="!subItem.hidden"
                  @click="execute(item.tag, subItem.to, subItem.title)"
                  :class="`${
                    isActiveItem(subItem) ? `active-item` : ''
                  } py-1 my-1`"
                >
                  <v-list-item-icon :class="`${isExpanded ? 'ml-4' : ''}`">
                    <v-icon
                      :class="`${
                        isActiveItem(subItem) ? `active-item-icon` : ''
                      }`"
                      v-text="subItem.icon"
                    />
                  </v-list-item-icon>
                  <v-list-item-title v-text="subItem.title" />
                  <v-list-item-action
                    v-if="subItem.beta"
                    class="my-0"
                    style="min-width: auto !important"
                  >
                    <v-chip
                      color="blue"
                      dark
                      :title="$t('common.availableIndefinitely')"
                      x-small
                      class="font-weight-medium"
                      >Beta
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list-group>

            <v-list-item
              v-else-if="!item.submenu"
              @click="execute(item.tag, item.to, item.title)"
              :class="`${
                isActiveItem(item) || isHiddenPage(item) ? 'active-item' : ''
              } py-1 my-1`"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="item.icon"
                  v-text="item.icon"
                  :class="`${
                    isActiveItem(item) || isHiddenPage(item)
                      ? 'active-item-icon'
                      : ''
                  }`"
                />
                <img v-if="item.logo" :src="item.logo" height="24" />
              </v-list-item-icon>
              <v-list-item-title v-text="item.title" />
              <v-list-item-action
                v-if="item.beta"
                class="my-0"
                style="min-width: auto !important"
              >
                <v-chip
                  color="blue"
                  dark
                  :title="$t('common.availableIndefinitely')"
                  x-small
                  class="font-weight-medium"
                  >Beta
                </v-chip>
              </v-list-item-action>
              <v-list-item-action
                v-else-if="item.new"
                class="my-0"
                style="min-width: auto !important"
              >
                <v-chip color="success" dark x-small class="font-weight-medium"
                  >{{ $t("common.new") }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <div>
        <v-list nav dense dark>
          <v-list-item
            v-if="!hasIntermediaryPartner && !showMini"
            :class="`py-0 mb-5 rounded-lg`"
            style="background-color: #eb5c1d !important"
            @click="
              execute('billing', '/billing/upgrade', 'Contratar um plano')
            "
          >
            <v-list-item-icon>
              <v-icon v-text="'ph-gift'" color="white" />
            </v-list-item-icon>
            <v-list-item-content class="pa-0 ma-0">
              <v-list-item-title class="white--text font-weight-bold px-0 mx-0">
                {{ $t("sidebar.hirePlan") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="!hasIntermediaryPartner && showMini"
            style="background-color: #eb5c1d !important"
            class="py-0 mb-2 rounded-lg"
            @click="
              execute('billing', '/billing/upgrade', 'Contratar um plano')
            "
          >
            <v-list-item-icon class="ml-0">
              <v-icon v-text="'ph-gift'" color="white" />
            </v-list-item-icon>
            <v-list-item-title
              class="white--text"
              v-text="$t('sidebar.hirePlan')"
            />
          </v-list-item>
          <v-divider class="border-opacity-25 mb-3" color="#896bbe" />
          <v-list-item class="feedback-button py-1 my-1" link>
            <v-list-item-icon>
              <v-icon v-text="'ph-chat-circle-dots'" />
            </v-list-item-icon>
            <v-list-item-title v-text="$t('feedbackRating.sendSugestion')" />
          </v-list-item>
          <v-list-item
            v-if="hasConectaSupport"
            @click="openChat"
            class="py-1 my-1"
          >
            <v-list-item-icon>
              <v-icon v-text="'ph-headset'" />
            </v-list-item-icon>
            <v-list-item-title v-text="$t('techDiagnosis.chatSupport')" />
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { goToControl } from "@/helpers/services/utils";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      rail: false,
      drawer: true,
      successMessages,
      errorMessages,
      showSidebar: true,
      sidebarExpanded: "300",
      sidebarSmall: "72",
      isExpanded: true,
    };
  },
  computed: {
    ...mapGetters([
      "isReseller",
      "isConecta",
      "company",
      "hasIntermediaryPartner",
      "sidebarMini",
      "sidebar",
      "currentUser",
      "hasConectaSupport",
      "displayOnlyPrimaryEmails",
      "mainDomain",
      "companyFreshChatConfig",
      "conectaNuvemResellerToken",
      "isCompanyMicrosoft",
    ]),

    isConectaSignEditor() {
      const path = this.$route.path;
      let query = path.split("/");
      let pathWithoutKey = `/${query[1]}/${query[2]}`;
      if (query[3] === "vacation-message") {
        pathWithoutKey += `/${query[3]}s`;
      }
      return pathWithoutKey;
    },

    showMini: {
      get() {
        return this.sidebarMini;
      },
      set(new_value) {
        this.setSidebarMini(new_value);
      },
    },

    sidebarWidth() {
      return this.$vuetify.breakpoint.lgAndUp;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    menu() {
      return [
        {
          title: "",
          menus: [
            {
              title: this.$t("sidebar.home"),
              icon: "ph-house-line",
              tag: "",
              to: "/",
            },
            {
              title: this.$t("sidebar.techDiagnosis"),
              icon: "ph-list-magnifying-glass",
              tag: "",
              to: "/workspace-checklist",
              hidden: this.isCompanyMicrosoft,
            },
            {
              title: this.$t("sidebar.patnerPanel"),
              icon: "ph-bank",
              tag: "reseller",
              hiddenRoutes: [
                "/partner/billing/transactions",
                "/partner/customer",
                "/partner/customer/plans/new",
              ],
              value: false,
              hidden: !this.isReseller,
              submenu: [
                {
                  icon: "ph-address-book-tabs",
                  title: this.$t("sidebar.clients"),
                  to: "/partner/customers",
                },
                {
                  icon: "ph-credit-card",
                  title: this.$t("sidebar.billing"),
                  to: "/partner/billing",
                },
                {
                  icon: "ph-gift",
                  title: this.$t("sidebar.plans"),
                  to: "/admin/plans/edit",
                  hidden: !this.isConecta,
                },
                {
                  icon: "ph-gear",
                  title: this.$t("sidebar.services"),
                  to: "/admin/services/edit",
                  hidden: !this.isConecta,
                },
                {
                  icon: "ph-bell",
                  title: this.$t("sidebar.notificationCenter"),
                  to: "/admin/notifications",
                  hidden: !this.isConecta,
                },
                {
                  icon: "ph-user-circle-dashed",
                  title: this.$t("sidebar.clientsBans"),
                  to: "/admin/ban",
                  hidden: !this.isConecta,
                },
              ],
            },
          ],
        },
        {
          title: this.$t("sidebar.toManage").toUpperCase(),
          menus: [
            {
              title: this.$t("sidebar.onboardingUser"),
              icon: "ph-user-plus",
              tag: "conecta_suite",
              to: "/onboarding-new-user",
              hidden: this.isCompanyMicrosoft,
            },
            {
              title: this.$t("sidebar.offboardingUser"),
              icon: "ph-user-minus",
              tag: "conecta_suite",
              to: "/offboarding-user",
              new: true,
              hidden: this.isCompanyMicrosoft,
            },
            {
              title: this.$t("sidebar.assignVacationMode"),
              icon: "ph-island",
              tag: "conecta_suite",
              to: "/sign/vacation-message",
              new: true,
              hidden: this.isCompanyMicrosoft,
            },
            {
              title: this.$t("tableHeaders.users"),
              icon: "ph-users",
              to: "/users",
              tag: "conecta_suite",
              hiddenRoutes: ["/user"],
            },
            {
              title: this.$t("sidebar.company"),
              icon: "ph-buildings",
              tag: "company",
              value: false,
              submenu: [
                {
                  title: this.$t("sidebar.profile"),
                  icon: "ph-buildings",
                  borderStyle: "suite-menu-border",
                  to: "/settings/profile",
                  tag: "company_profile",
                },
                {
                  title: this.$t("sidebar.inventory"),
                  icon: "ph-clipboard",
                  to: "/inventory",
                  tag: "inventory",
                  value: false,
                  beta: true,
                  hidden: this.isCompanyMicrosoft,
                },
                {
                  title: this.$t("sidebar.reports"),
                  icon: "ph-chart-line-up",
                  tag: "reports",
                  to: "/reports",
                  value: false,
                  submenu: [
                    {
                      icon: "ph-chart-bar",
                      title: this.$t("sidebar.general"),
                      to: "/reports",
                      tag: "reports",
                    },
                    {
                      icon: "ph-article-medium",
                      title: this.$t("sidebar.emailSignatures"),
                      to: "/reports/signatures",
                      tag: "reports",
                    },
                    {
                      icon: "ph-user",
                      title: this.$t("sidebar.accounts"),
                      to: "/reports/accounts",
                      tag: "accounts",
                    },
                  ],
                },
              ],
            },
            {
              title: this.$t("sidebar.permissions"),
              icon: "ph-lock-key",
              to: "/settings/user-permissions",
              tag: "manage_admins",
            },
          ],
        },
        {
          title: this.$t("companyProfile.email").toUpperCase(),
          menus: [
            {
              icon: "ph-article-medium",
              title: this.$t("sidebar.emailSignatures"),
              to: "/sign/email-signature/templates",
            },
            {
              icon: "ph-chat-text",
              title: this.$t("sidebar.vacationMessages"),
              to: "/sign/templates/vacation-messages",
              hidden: this.isCompanyMicrosoft,
            },
            {
              icon: "ph-clock-clockwise",
              title: this.$t("sidebar.signAutomation"),
              to: "/sign/users/sign-automation",
              hidden: this.isCompanyMicrosoft,
            },
          ],
        },
        {
          title: this.$t("sidebar.control").toUpperCase(),
          menus: [
            {
              title: this.$t("sidebar.conectaControl"),
              borderStyle: "control-menu-border",
              logo: require("@/assets/appslogos/icons/IconControlBranco.png"),
              custom_action: "open-control-dialog",
              tag: "conecta_control",
            },
          ],
        },
      ];
    },
  },

  watch: {
    sidebarWidth() {
      if (this.sidebarWidth) {
        this.setSidebarMini(true);
      } else {
        this.setSidebarMini(false);
      }
    },
    sidebar() {
      this.showSidebar = this.sidebar;
    },
    isMobile() {
      if (this.isMobile) {
        this.showSidebar = false;
        this.rail = false;
        this.isExpanded = true;
        this.setSidebarMini(false);
      }
    },
  },

  methods: {
    ...mapMutations([
      "setSidebarMini",
      "setDisplayPrimaryEmails",
      "setSignatureSuccessHintScreen",
    ]),
    ...mapActions(["initSupportChat"]),

    async openChat() {
      await this.initSupportChat();
    },

    toggleMenu() {
      if (this.isMobile) {
        this.showSidebar = !this.showSidebar;
      } else {
        this.rail = !this.rail;
        this.isExpanded = !this.isExpanded;
      }
    },

    goToindexPath() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },

    isActiveItem(menu_item) {
      return (
        this.$route.path === menu_item.to ||
        menu_item.to === this.isConectaSignEditor
      );
    },

    isActiveGroup(menu_item) {
      return menu_item.submenu.find((subitem) => this.isActiveItem(subitem))
        ? true
        : false;
    },

    isHiddenPage(menu_item) {
      return (
        menu_item.hiddenRoutes &&
        menu_item.hiddenRoutes.includes(this.$route.path)
      );
    },

    setPath(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push({ path: path });
      }
    },

    execute(menuItem, path, title) {
      const { main_domain } = this.company;
      //TODO: remover essa lógica quando houver permissionamento para reports
      const analytics = getAnalytics();
      logEvent(analytics, "navigated_by", {
        menuItem,
        path,
        title,
        navigation_by: "Sidebar",
        main_domain,
      });

      // Se for control, mesmo sem ACCESS_ALLOWED, usuário é redirecionado
      if (menuItem === "conecta_control") {
        goToControl();
      } else if (path === "/users") {
        this.setSignatureSuccessHintScreen();
        this.setPath(path);
      } else {
        if (path.includes("/billing/upgrade")) {
          logEvent(analytics, "go_to_self_checkout", {
            click: "sidebar",
            main_domain: this.mainDomain,
            email: this.currentUser.email,
            description: `Clicou no botão 'Contratar um plano ou serviço' da barra lateral (sidebar)`,
          });
        }

        this.setPath(path);
      }
    },
  },

  beforeMount() {
    this.showSidebar = this.$vuetify.breakpoint.mobile ? false : this.sidebar;
  },
};
</script>
<style>
.nav-icon {
  transition: transform 0.5s ease;
}

.rotate-icon {
  transform: rotate(180deg);
}

.list-container {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.list-container::-webkit-scrollbar {
  width: 5px;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: #5a487a;
  border-radius: 4px;
}

.list-container::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .v-navigation-drawer--mini-variant {
  width: 72px;
} */

/* .active-group {
  color: #ddc9ff !important;
  transition: 0.5s ease;
} */

.active-group {
  /* outline: 1px solid #ddc9ff; */
  background-color: #473074;
  border-radius: 5px;
  color: #ddc9ff !important;
}

.active-item {
  box-shadow: -5px 0px 0px 0px #9e7fd4;
  background: #55378d !important;
  color: #ddc9ff !important;
  transition: 0.5s ease;
}

.active-item-icon {
  color: #ddc9ff !important;
}

.link:hover {
  cursor: pointer;
}
</style>
