var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasTrialPlans)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{class:`d-flex align-center justify-start rounded-pill ${_vm.trialBarClass} py-2  ${
        _vm.mobile ? 'px-0' : 'mx-2 px-3'
      }`,staticStyle:{"max-height":"40px !important"}},[(_vm.mobile && _vm.hasTrialPlans)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"overflow":"hidden"}},_vm._l((_vm.trialPlans),function(item,index){return _c('div',{key:index,staticClass:"mr-2"},[_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('span',{staticClass:"d-flex align-center mt-1",on:{"mouseover":function($event){_vm.selectedPlan = item}}},[_c('Logo',{attrs:{"product":item.plan.short_code,"width":"38","height":"38","noMargins":"","customClass":"mr-2"}})],1)])])}),0):(_vm.hasTrialPlans)?_c('div',{staticClass:"d-flex align-center"},[(_vm.featuredPlan)?_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center",on:{"mouseover":function($event){_vm.selectedPlan = _vm.featuredPlan}}},[_c('Logo',{attrs:{"product":_vm.featuredPlan.plan.short_code
                  ? _vm.featuredPlan.plan.short_code
                  : 'plans',"width":"30","height":"30","noMargins":"","customClass":"mr-2"}}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-column mx-2 my-1"},[_c('div',{staticClass:"caption font-weight-medium my-0 py-0"},[_vm._v(" Dias restantes ")]),_c('div',{staticClass:"caption my-0 py-0"},[_vm._v(" "+_vm._s(_vm.endTrial(_vm.featuredPlan.end_trial))+" ")])])])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-column mx-2 my-1"},[_c('div',{staticClass:"caption font-weight-medium my-0 py-0"},[_vm._v(" Planos em TRIAL ")])])]),(_vm.trialPlans.length > 1 && _vm.showOtherPlans && _vm.featuredPlan)?_c('v-divider',{staticClass:"ml-2 mr-3 my-3",staticStyle:{"border-left":"1px solid #fff"},attrs:{"vertical":""}}):_vm._e(),(_vm.showOtherPlans)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.othersPlans),function(otherPlan,i){return _c('v-btn',{key:i,staticClass:"mx-1",attrs:{"icon":"","small":"","rounded":"","title":otherPlan.plan.name + ' - ' + _vm.endTrial(otherPlan.end_trial)},on:{"click":function($event){_vm.featuredPlan = otherPlan}}},[(_vm.daysToEndTrial(otherPlan.end_trial) < 0)?_c('v-badge',{attrs:{"bottom":"","color":"error","small":"","dot":"","offset-x":"8","offset-y":"8"}},[_c('Logo',{attrs:{"product":otherPlan.plan.short_code
                    ? otherPlan.plan.short_code
                    : 'plans',"width":"28","height":"28","noMargins":""},on:{"mouseover":function($event){_vm.featuredPlan = otherPlan}}})],1):_c('Logo',{attrs:{"product":otherPlan.plan.short_code
                  ? otherPlan.plan.short_code
                  : 'plans',"width":"28","height":"28","noMargins":""},on:{"mouseover":function($event){_vm.featuredPlan = otherPlan}}})],1)}),1):_vm._e(),(_vm.featuredPlan && _vm.othersPlans.length > 0)?_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","x-small":"","title":_vm.showOtherPlans
              ? 'Ocultar outros planos em TRIAL'
              : 'Mostrar outros planos em TRIAL'},on:{"click":function($event){_vm.showOtherPlans = !_vm.showOtherPlans}}},[_c('v-icon',{attrs:{"color":""},domProps:{"textContent":_vm._s(_vm.showOtherPlans ? 'ph-caret-left' : 'ph-caret-right')}})],1):_vm._e()],1):_vm._e()])]}}],null,false,1726913022)},[(_vm.selectedPlan)?_c('v-card',{staticClass:"d-flex flex-column justify-center elevation-0",attrs:{"width":"340"}},[_c('v-card-title',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex justify-center align-center ma-2 py-0"},[_c('span',{staticClass:"font-weight-bold text-wrap"},[_vm._v(_vm._s(_vm.selectedPlan.plan.name))]),_c('v-chip',{staticClass:"ml-4",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.selectedPlan.status)+" ")])],1)]),_c('v-divider'),_c('div',{staticClass:"py-4 mx-3 d-flex flex-column"},[_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Dias restantes:")]),(
            _vm.daysToEndTrial(_vm.selectedPlan.end_trial) === 0 &&
            _vm.checkExpiresToday(_vm.selectedPlan.end_trial)
          )?_c('span',{staticClass:"pl-3"},[_vm._v(" Último dia de trial ")]):_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.daysToEndTrial(_vm.selectedPlan.end_trial) > 0 ? _vm.daysToEndTrial(_vm.selectedPlan.end_trial) : "Expirado")+" ")])]),_c('v-card-text',{staticClass:"py-1"},[(_vm.daysToEndTrial(_vm.selectedPlan.end_trial) > 0)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Trial termina em:")]):(
            _vm.daysToEndTrial(_vm.selectedPlan.end_trial) == 0 &&
            _vm.checkExpiresToday(_vm.selectedPlan.end_trial)
          )?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Trial termina hoje:")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v("Trial terminou em:")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.formatDateFullMonth(_vm.selectedPlan.end_trial)))])]),_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nº de licenças:")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.usersNumber)+" ")])]),_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Criado em:")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.formatDateFullMonth(_vm.selectedPlan.init_date)))])]),_c('v-card-text',{staticClass:"py-1 font-weight-bold"},[_vm._v("Produtos:")]),_c('v-card-actions',{staticClass:"align-start mx-2 flex-wrap"},_vm._l((_vm.products),function(product){return _c('span',{key:product,staticClass:"mr-2",staticStyle:{"max-width":"40px"}},[_c('Logo',{attrs:{"products":"","product":product,"height":"30","no-margins":""}})],1)}),0),_c('v-btn',{staticClass:"mb-3 px-4 mt-5 mx-auto elevation-0 text-none text-body-2 font-weight-medium rounded-pill px-8",attrs:{"color":"success","dark":"","large":""},on:{"click":function($event){return _vm.requestActivation(_vm.selectedPlan.plan.name)}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('ph-gift')}}),_vm._v(" Contratar plano ")],1),_c('v-btn',{staticClass:"mx-auto mb-2 text-none caption font-weight-medium transparent",attrs:{"color":"grey darken-2","text":"","x-small":"","to":{ path: '/company' }}},[_vm._v(" Informações da Empresa ")])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }