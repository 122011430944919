<template>
  <v-btn
    text
    :small="small"
    :color="colors[product]"
    class="pl-1 pr-0 text-none text-subtitle-2 font-weight-medium"
    @click="goTo"
  >
    <div :style="`max-width: ${small ? '20' : '40'}px`" class="mr-1">
      <Logo :product="product" :width="small ? 30 : 35" no-margins />
    </div>
    {{ productNames[product] }}
    <v-icon v-if="href" right size="18" v-text="'ph-arrow-square-out'" />
  </v-btn>
</template>
<script>
import { openLink } from "@/helpers/services/utils";
import { productNames } from "@/helpers/variables/translateString";
export default {
  props: {
    small: { type: Boolean, default: false },
    href: { type: String, default: null },
    product: { type: String, default: "conecta_control" },
    goToProduct: { type: Boolean, default: false },
  },
  data() {
    return {
      productNames,
      colors: {
        conecta_control: "primaryControl",
        conecta_sign: "primarySign",
        conecta_suite: "primary",
        conecta_track: "primaryTrack",
      },
      route: {
        conecta_control: process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL,
        conecta_sign: "/sign",
        conecta_suite: "/",
        conecta_track: "/track",
      },
    };
  },
  methods: {
    goTo() {
      if (this.href) {
        openLink(this.href);
      } else {
        this.$router.push(this.route[this.product]);
      }
    },
  },
};
</script>
<style>
.user-badge .v-list-item:hover {
  background: "transparent";
}
</style>
