<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        class="ms-1 me-5"
        v-on="on"
        @click.stop="togglePostponeRoamingDialog(true)"
      >
        <v-icon color="primary" v-text="isDashTester ? 'ph-gear' : 'ph-gear'" />
        <v-badge
          :color="postponeRoaming ? 'success' : 'error'"
          :content="postponeRoaming ? 'ON' : 'OFF'"
        >
        </v-badge>
      </v-btn>
    </template>
    <span>{{ $t("activateMicrosoft.button.label") }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ActionPostponeRoaming",

  computed: {
    ...mapGetters(["isDashTester", "postponeRoaming"]),
  },

  methods: {
    ...mapMutations(["togglePostponeRoamingDialog"]),
  },
};
</script>
