<template>
  <v-dialog v-model="showDialog" max-width="1000">
    <v-card>
      <v-toolbar elevation="0" dense>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>ph-x</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="ma-0">
        <v-col v-if="$vuetify.breakpoint.mdAndUp">
          <v-img
            max-width="450px"
            :src="actionsData[action].photo"
            alt="schedule meeting"
            class="mb-6"
          ></v-img>
          <a style="font-size: 10px" href="https://storyset.com/work"
            >Work illustrations by Storyset</a
          >
        </v-col>
        <v-col
          style="display: flex; justify-content: center; align-items: center"
        >
          <div>
            <div
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-img
                max-width="250px"
                :src="actionsData[action].photo"
                alt="schedule meeting"
                class="mb-6"
              ></v-img>
            </div>
            <v-card-title
              class="title text-h4 primary--text font-weight-bold text-wrap break-word"
            >
              {{
                isHelpBanner
                  ? `Ei ${userFirstName}!`
                  : actionsData[action].title
              }}
            </v-card-title>
            <v-card-subtitle class="text-h5 text--bold title font-weight-bold">
              {{ actionsData[action].subtitle }}
            </v-card-subtitle>
            <v-card-text class="py-4 body-1">
              {{ actionsData[action].message }}
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-btn
                v-if="!isSmallClient"
                color="primary"
                class="white--text text-none text-body-1"
                id="teamMeetingBtn"
                width="100%"
                large
                rounded
                elevation="0"
                @click="scheduleMeeting"
              >
                Fale com nossos especialistas
                <v-icon v-text="'ph-arrow-right'" class="next-icon-1" right />
              </v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn
                color="accent"
                outlined
                class="white--text text-none text-body-1"
                id="redirectBtn"
                width="100%"
                large
                rounded
                elevation="0"
                @click="redirectBtnLink"
              >
                {{ isHelpBanner ? "Perguntas Frequentes" : "Conhecer planos" }}
                <v-icon v-text="'ph-arrow-right'" class="next-icon-2" right />
              </v-btn>
            </v-card-actions>
            <a
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="font-size: 10px"
              href="https://storyset.com/work"
              >Work illustrations by Storyset</a
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { redirectToTheCommercialManagerGoogleCalendar } from "@/helpers/services/utils";
import { links } from "@/helpers/variables/links";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ActionsScheduleMeeting",

  data: () => ({
    links,
    redirectToTheCommercialManagerGoogleCalendar,
    actionsData: {
      help: {
        title: `Ei! Tudo bem?`,
        subtitle: "Precisando de ajuda?",
        message:
          "Saiba como utilizar assinaturas de email profissionais para elevar os resultados da sua empresa",
        photo: require("@/assets/images/modelo_assinatura_reuniao.svg"),
      },
    },
  }),

  computed: {
    ...mapGetters([
      "mainDomain",
      "currentUser",
      "company",
      "loading",
      "primaryColor",
      "secondaryColor",
      "showActionsScheduleDialog",
      "getActionsScheduleType",
      "isSmallClient",
    ]),

    action() {
      return this.getActionsScheduleType;
    },

    isHelpBanner() {
      return this.getActionsScheduleType == "help";
    },

    userFirstName() {
      if (this.currentUser) {
        const names = this.currentUser.name.split(" ");
        return names[0];
      } else {
        return "teste";
      }
    },

    showDialog: {
      get() {
        return this.showActionsScheduleDialog;
      },
      set(newVal) {
        this.setActionScheduleDialog({ status: newVal });
      },
    },
  },

  methods: {
    ...mapMutations(["setActionScheduleDialog"]),

    redirectBtnLink() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from help banner";
      const user = this.currentUser.email;

      if (this.isHelpBanner) {
        logEvent(analytics, "contact_us", {
          main_domain,
          user,
          clicked_where,
          date: moment().format("DD/MM/YYYY"),
        });
        this.showDialog = false;
        this.$router.push({ name: "ContactUs" });
      } else {
        logEvent(analytics, "go_to_self_checkout", {
          click: "schedule_dialog_when_user_need_help",
          main_domain: this.mainDomain,
          email: this.currentUser.email,
          description:
            "Clicou no botão de 'Conhecer planos' da modal de agendamento (quando o usuário está com dúvida, exemplo: assinatura de e-mail)",
        });

        this.showDialog = false;
        this.$router.push({ name: "BillingUpgradePlan" });
      }
    },

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from help modal";
      const clicked_from = "Modal de ajuda (ícone de ajuda)";
      const user = this.currentUser.email;

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        user,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      this.showDialog = false;
      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },
  },
};
</script>

<style scoped>
.v-btn:hover .next-icon-1,
.v-btn:hover .next-icon-2 {
  transform: translateX(5px); /* Ajuste o valor conforme necessário */
  transition: transform 0.3s ease-in-out;
}
</style>
