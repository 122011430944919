<template>
  <v-alert
    :outlined="outlined"
    :text="text"
    :prominent="prominent"
    :icon="getIcon"
    :elevation="elevation"
    :max-width="max_width"
    :color="color"
    :dense="dense"
    :dark="dark || !text"
    :tile="tile"
    @click="changeShowHide"
    :class="`${expansion ? 'cursor-pointer' : ''}`"
  >
    <v-row
      align="center"
      :class="`pa-0 ma-0 ${prominent ? 'mx-4' : 'mx-1'} ${
        dense ? 'text-body-2' : ''
      }`"
      dense
      no-gutters
    >
      <v-col
        cols="12"
        :lg="actionText ? 9 : 12"
        sm="12"
        :class="`${
          $vuetify.theme.dark || dark || !text
            ? 'white--text'
            : `${color}--text text--darken-4`
        } ${text ? `${color}--text text--darken-2` : ''} alert-link pa-0`"
      >
        <slot />
      </v-col>
      <v-col
        v-if="actionText"
        cols="12"
        lg="3"
        sm="12"
        :class="`${
          $vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''
        } pa-0 ${$vuetify.breakpoint.smAndDown ? 'mt-4' : ''}`"
      >
        <v-btn
          :dark="!text || dark"
          :text="text"
          :color="text ? color : ''"
          small
          class="text-body-1 font-weight-medium"
          :outlined="$vuetify.breakpoint.smAndDown"
          :block="$vuetify.breakpoint.smAndDown"
          @click="$emit('action')"
        >
          <v-icon v-if="actionIcon" left v-text="actionIcon" />
          {{ actionText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row
        v-if="showHideExpansion && expansion"
        align="center"
        :class="`pa-0 ma-0 ${prominent ? 'mx-4' : 'mx-1'} ${
          dense ? 'text-body-2' : ''
        } ${
          $vuetify.theme.dark || dark || !text
            ? 'white--text'
            : `${color}--text text--darken-4`
        } ${text ? `${color}--text text--darken-2` : ''}`"
        dense
        no-gutters
      >
        <slot name="expansion" />
      </v-row>
    </v-expand-transition>
  </v-alert>
</template>
<script>
export default {
  name: "Alert",
  props: {
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: true },
    tile: { type: Boolean, default: false },
    prominent: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    icon: { type: String, default: "" },
    elevation: { type: String, default: "0" },
    max_width: { type: String, default: "" },
    color: { type: String, default: "info" },
    actionText: { type: String, default: "" },
    actionIcon: { type: String, default: "ph-x" },
    expansion: { type: Boolean, default: false },
    btnAction: { type: Function },
  },
  data() {
    return {
      icons: {
        warning: "ph-warning",
        error: "ph-x-circle",
        info: "ph-info",
        success: "ph-check-circle",
      },
      showHideExpansion: false,
    };
  },
  computed: {
    getIcon() {
      return this.icon === "" ? this.icons[this.color] : this.icon === "";
    },
  },
  methods: {
    changeShowHide() {
      this.showHideExpansion = !this.showHideExpansion;
    },
  },
};
</script>
