<template>
  <v-app-bar
    color="transparent"
    elevation="0"
    clipped-left
    clipped-right
    app
    :flat="$vuetify.theme.dark || isTemplateEditor || isNewTemplateEditor"
    :class="`px-0 ${$vuetify.theme.dark ? 'border-bottom' : ''}`"
    :extended="isTemplateEditor"
    :extension-height="isTemplateEditor ? 40 : 0"
  >
    <v-app-bar-nav-icon
      v-if="!isNewTemplateEditor && !isUpgradePlanPage"
      class="ml-0 pl-0"
      @click="setSidebarView"
    />

    <v-app-bar-nav-icon v-else-if="isUpgradePlanPage" class="ml-0">
      <v-btn icon @click="redirectToHome" :ripple="false">
        <v-icon v-text="'ph-house'" />
      </v-btn>
    </v-app-bar-nav-icon>

    <!-- TOOLBAR -->
    <v-toolbar-title
      v-if="!isCellphone"
      :style="`${isMobile ? 'width: 75px' : 'width: 260px'};`"
      class="ml-0 px-5 d-flex align-center link"
      @click="goToindexPath()"
    >
      <v-img
        v-if="!isMobile"
        max-width="170"
        src="@/assets/appslogos/full/conecta_suite_full.png"
      />
      <Logo v-if="isMobile" product="conecta_suite" height="30" no-margins />
    </v-toolbar-title>

    <!-- Search input -->
    <v-autocomplete
      class="hidden-sm-and-down"
      :loading="showLoading || loading"
      :disabled="showLoading || loading"
      :items="listUsersByEmail"
      item-text="email"
      :filter="customFilter"
      label="Pesquisar usuário..."
      color="primary"
      item-color="grey darken-1"
      flat
      hide-no-data
      hide-details
      solo
      prepend-inner-icon="ph-magnifying-glass"
      v-model="selectUser"
      v-if="isNotMobile"
      background-color="#f1f4f8"
      style="outline: 1px solid #d4d6da; max-width: 600px"
    >
      <template v-slot:item="{ on, item }">
        <v-list-item v-on="on" @click="goUserPage(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.email }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-autocomplete
      class="d-inline d-xs-flex d-md-none mr-8"
      size="700"
      :loading="showLoading || loading"
      :disabled="showLoading || loading"
      :items="users"
      item-text="email"
      :filter="customFilter"
      label="Usuário"
      color="primary"
      item-color="grey darken-1"
      flat
      hide-no-data
      hide-details
      solo-inverted
      prepend-inner-icon="ph-magnifying-glass"
      v-model="selectUser"
      v-if="isNotMobile"
    >
      <template v-slot:item="{ on, item }">
        <v-list-item v-on="on" @click="goUserPage(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.email }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-spacer />

    <Missions v-if="!isCellphone && !isCompanyMicrosoft" custom-class="mr-5" />

    <v-chip
      v-if="
        isSuiteClient &&
        !isSmallClient &&
        showScheduleMeeting &&
        !IsScheduleMeetingSnackbarVisible &&
        !loading &&
        ($vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl)
      "
      class="pl-0"
      @click="scheduleMeeting"
      outlined
      pill
    >
      <v-badge color="green" dot bottom offset-x="10" offset-y="10">
        <v-avatar>
          <v-img
            aspect-ratio="1/1"
            :src="links.commercial_responsable_img"
            alt="user photo"
            contain
          ></v-img>
        </v-avatar>
      </v-badge>

      <span class="ml-4">Agendar reunião</span>
    </v-chip>

    <!-- TRIAL STATUS -->
    <TrialBar
      v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
      :show="hasTrialPlans"
      @snackbarPurchaseInterest="snackbarPurchaseInterest"
    />

    <!-- BOTÃO DE PESQUISA MOBILE -->
    <v-menu
      v-if="isMobile"
      offset-y
      left
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon class="mr-2" v-bind="attrs" v-on="{ ...menu }">
          <v-icon color="primary" v-text="'ph-magnifying-glass'" />
        </v-btn>
      </template>
      <v-card class="px-6 pt-4 pb-0">
        <div class="mb-2 font-weight-bold">Pesquisar usuário</div>
        <v-autocomplete
          class="hidden-sm-and-down"
          :loading="showLoading || loading"
          :disabled="showLoading || loading"
          :items="listUsersByEmail"
          item-text="email"
          :filter="customFilter"
          label="Pesquisar usuário"
          color="primary"
          item-color="grey darken-1"
          flat
          hide-no-data
          hide-details
          solo
          outlined
          prepend-inner-icon="ph-magnifying-glass"
          v-model="selectUser"
        >
          <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" @click="goUserPage(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.email }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template></v-autocomplete
        >

        <v-autocomplete
          class="d-inline d-xs-flex d-md-none mr-8"
          size="700"
          :disabled="showLoading || loading"
          :loading="showLoading || loading"
          :items="users"
          item-text="email"
          :filter="customFilter"
          label="Usuário"
          color="primary"
          item-color="grey darken-1"
          flat
          hide-no-data
          hide-details
          solo
          outlined
          prepend-inner-icon="ph-magnifying-glass"
          v-model="selectUser"
        >
          <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" @click="goUserPage(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.email }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card>
    </v-menu>

    <!-- TAREFAS -->
    <v-menu
      v-if="!isCompanyMicrosoft"
      offset-y
      left
      :close-on-content-click="false"
      v-model="handleTasksProgress"
      max-height="400"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon class="mx-0" v-on="{ ...tooltip, ...menu }">
              <v-badge
                :content="1"
                :value="runningDriveProcessPath"
                overlap
                color="red"
              >
                <v-icon color="primary">mdi-timer-sand-empty</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("common.tasks") }}</span>
        </v-tooltip>
      </template>
      <Tasks v-if="handleTasksProgress" />
    </v-menu>

    <!-- BOTAO DE AJUDA -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon class="mx-1" v-on="on" @click.stop="$emit('openSupport')">
          <!--
            add this badge if somenhing new:
            removed in: https://conectanuvem.atlassian.net/browse/CN-5658
            <v-badge v-if="!allFAQSeen" overlap color="success" dot>
            <v-icon v-text="'ph-question'" />
          </v-badge> -->
          <v-icon color="primary" v-text="'ph-question'" />
        </v-btn>
      </template>
      <span>Suporte</span>
    </v-tooltip>

    <!-- BOTAO DE NOTIFICACOES -->
    <v-menu bottom offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              class="mx-1"
              v-on="{ ...tooltip, ...menu }"
              @click="toggleNofitications()"
            >
              <v-badge
                :content="totalNotificationsUnread"
                :value="totalNotificationsUnread"
                overlap
                color="red"
              >
                <v-icon color="primary" v-text="'ph-bell'" />
              </v-badge>
            </v-btn>
          </template>
          <span>Notificações</span>
        </v-tooltip>
      </template>
    </v-menu>

    <!-- BOTÃO DE POSTPONE ROAMING -->
    <ActionPostponeRoaming v-if="isCompanyMicrosoft" />

    <!-- FOTO E MENU -->
    <v-menu offset-y left :close-on-content-click="false" v-model="userCard">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="30" v-bind="attrs" v-on="on" class="mx-2">
          <img v-if="currentUser.photo" alt="Avatar" :src="currentUser.photo" />
          <v-icon
            v-else
            v-text="'ph-user-circle'"
            size="30"
            :alt="currentUser.username"
          />
        </v-avatar>
      </template>

      <v-card width="350" class="py-4">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar size="80">
            <img
              v-if="currentUser.photo"
              alt="Avatar"
              :src="currentUser.photo"
            />
            <v-icon
              v-else
              v-text="'ph-user-circle'"
              size="80"
              :alt="currentUser.name"
            />
          </v-avatar>

          <v-card-title>{{ currentUser.name }}</v-card-title>
          <v-card-subtitle>{{ currentUser.email }}</v-card-subtitle>
        </v-card-text>

        <UpgradeButton @closeUserMenu="userCard = false" />

        <CurrentPlanBanner />

        <v-divider v-if="!currentPlan" />

        <v-list-item class="mt-2" @click="goToCompanyProfile">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-gear'" /> Perfil da empresa
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          class="feedback-button"
          link
          @click="feedbackButtonClick()"
        >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-chat-circle-dots'" />
              Enviar sugestão
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="hasConectaSupport" @click="openChat">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-headset'" /> Suporte via chat
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item class="mb-2" @click.stop="dialogLogout = true">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-sign-out'" /> Sair
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-divider />

        <v-card-actions class="d-flex align-center justify-center pt-4">
          <v-btn
            x-small
            color="primary"
            class="caption text-none font-weight-medium"
            text
            href="https://conectasuite.com/politica-de-privacidade"
            target="_blank"
          >
            Políticas de privacidade
          </v-btn>
          <v-icon v-text="'mdi-circle-small'" small />
          <v-btn
            x-small
            color="primary"
            class="caption text-none font-weight-medium"
            text
            href="https://conectasuite.com/termos-de-uso"
            target="_blank"
          >
            Termos de uso
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <LogoutDialog :show="dialogLogout" @cancelLogout="cancelLogout" />

    <PostponeRoamingDialog v-if="isCompanyMicrosoft" />

    <v-progress-linear
      v-if="loading"
      indeterminate
      absolute
      bottom
      color="white"
    />

    <template
      v-if="isTemplateEditor"
      v-slot:extension
      style="border-top: 2px solid #fff"
    >
      <v-app-bar
        absolute
        dense
        class="mx-0"
        height="40"
        flat
        tile
        elevation="0"
      >
        <NewsTip
          name="cn-9542"
          :title="$t('understood.cn_9542.title')"
          :content="$t('understood.cn_9542.content')"
          :start-date="SPRINT_120_2ND_WEEK_START"
          :order="3"
        >
          <v-btn
            :to="routesToReturn[$route.name]"
            outlined
            dark
            class="primary--text text-none"
            small
          >
            <v-icon v-text="'ph-arrow-left'" left /> {{ $t("common.back") }}
          </v-btn>
        </NewsTip>
        <v-layout
          class="primary--text text-subtitle-2 text-none align-center justify-end mr-5"
        >
          <NewsTip
            name="cn-9543"
            :title="$t('understood.cn_9543.title')"
            :content="$t('understood.cn_9543.content')"
            :start-date="SPRINT_120_2ND_WEEK_START"
            :order="2"
            next-tip-name="cn-9542"
          >
            <v-menu v-if="$vuetify.breakpoint.lgAndUp" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  dark
                  elevation="0"
                  small
                  class="primary--text text-none text-subtitle-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'ph-fill ph-eye'" left />
                  {{ editorOptionsText }}
                  <v-icon v-text="'ph-caret-down'" right />
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="item in Object.values(signEditorLayout)"
                  :key="item"
                  class="text-subtitle-2"
                  link
                  @click="handleEditorLayout(item)"
                >
                  {{ item }}
                </v-list-item>
              </v-list>
            </v-menu>
          </NewsTip>
        </v-layout>
      </v-app-bar>
    </template>
  </v-app-bar>
</template>
<script>
import CurrentPlanBanner from "@/components/billing/CurrentPlanBanner";
import UpgradeButton from "@/components/billing/upgrade/UpgradeButton";
import LogoutDialog from "@/components/general/LogoutDialog";
import Missions from "@/components/main/dashboard/Missions";
import TrialBar from "@/components/main/dashboard/TrialBar";
import Tasks from "@/components/main/tasks/Tasks.vue";
import ActionPostponeRoaming from "@/components/microsoft/ActionPostponeRoaming.vue";
import PostponeRoamingDialog from "@/components/microsoft/PostponeRoamingDialog.vue";
import {
  initFreshChatGlobal,
  redirectToTheCommercialManagerGoogleCalendar,
} from "@/helpers/services/utils";
import { SPRINT_120_2ND_WEEK_START } from "@/helpers/variables/dates";
import { links } from "@/helpers/variables/links";
import { productNames } from "@/helpers/variables/translateString";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Navbar",
  components: {
    TrialBar,
    LogoutDialog,
    CurrentPlanBanner,
    UpgradeButton,
    Missions,
    Tasks,
    ActionPostponeRoaming,
    PostponeRoamingDialog,
  },
  props: {
    users: { type: [Array, Object], required: true },
  },
  data() {
    return {
      settings: false,
      showOnlineUsers: false,
      userCard: false,
      chipTextColor: "primary--text",
      indexPath: "/",
      product: "Conecta Suite",
      dialogLogout: false,
      showLoading: false,
      selectUser: null,
      productNames,
      signEditorLayout: {
        editor_only: "Somente editor",
        normal: "Editor e visualizador empilhados",
        side_to_side: "Editor e visualizador lado a lado",
      },
      keySignTemplate: {
        "Somente editor": "editor_only",
        "Editor e visualizador empilhados": "normal",
        "Editor e visualizador lado a lado": "side_to_side",
      },
      routesToReturn: {
        VacationTemplateEdit: "/sign/templates/vacation-messages",
        TemplateEdit: "/sign/template",
      },
      links,
      SPRINT_120_2ND_WEEK_START,
    };
  },
  computed: {
    ...mapGetters([
      "isUpgradePlanPage",
      "editorLayout",
      "vacationEditorLayout",
      "totalNotificationsUnread",
      "hasTrialPlans",
      "productName",
      "isSignPage",
      "isTrackPage",
      "isConecta",
      "currentUser",
      "loading",
      "company",
      "allFAQSeen",
      "currentPlan",
      "showScheduleMeeting",
      "IsScheduleMeetingSnackbarVisible",
      "mainDomain",
      "isSmallClient",
      "hasConectaSupport",
      "showTasksProgress",
      "runningDriveProcessPath",
      // "emailTransferInProgressPath",
      "companyFreshChatConfig",
      "conectaNuvemResellerToken",
      "isCompanyMicrosoft",
      "isSuiteClient",
    ]),

    isTemplateEditor() {
      return this.$route.name.includes("TemplateEdit");
    },

    isNewTemplateEditor() {
      return this.$route.name.includes("NewSignStyle");
    },

    editorOptionsText() {
      if (this.isSignatureTemplateEditor) {
        return this.signEditorLayout[this.editorLayout];
      }

      return this.signEditorLayout[this.vacationEditorLayout];
    },

    isSignatureTemplateEditor() {
      return this.$route.name === "TemplateEdit";
    },

    productKey() {
      let key = this.product.replace(" ", "_");
      return key.toLowerCase();
    },

    listUsersByEmail() {
      let users = new Array();
      this.users.map((item) => users.push(item.email));
      return this.users;
    },

    isNotMobile() {
      return !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs;
    },

    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },

    isCellphone() {
      return this.$vuetify.breakpoint.xs;
    },

    handleTasksProgress: {
      get() {
        return this.showTasksProgress;
      },
      set(value) {
        this.setShowTasksProgress(value);
      },
    },
  },
  watch: {
    "$route.path": function () {
      if (this.isTrackPage) {
        this.page = "track";
        this.product = "Conecta Track";
        this.indexPath = "/track/users";
        this.chipTextColor = "primaryTrack--text";
      } else if (this.isSignPage) {
        this.page = "sign";
        this.product = "Conecta Sign";
        this.indexPath = "/sign";
        this.chipTextColor = "primary--text";
      } else {
        this.page = "suite";
        this.product = "Conecta Suite";
        this.indexPath = "/";
        this.chipTextColor = "primary--text";
      }
    },
  },

  methods: {
    ...mapMutations([
      "setSidebarView",
      "showCloseNotificationsSidebar",
      "setEditorLayout",
      "setVacationEditorLayout",
      "feedbackButtonClick",
      "setShowTasksProgress",
    ]),
    initFreshChatGlobal,

    ...mapActions(["initSupportChat"]),

    async openChat() {
      await this.initSupportChat();
    },

    redirectToHome() {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: `Botão de ir para página inicial`,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou no botão para sair do self-checkout e ir para a página inicial",
      });

      this.$router.push({ name: "Home" });
    },

    goToCompanyProfile() {
      if (this.$route.path !== "/settings/profile") {
        this.$router.push({ path: "/settings/profile" });
      }
    },

    handleEditorLayout(item) {
      if (this.isSignatureTemplateEditor) {
        this.setEditorLayout(this.keySignTemplate[item]);
      } else {
        this.setVacationEditorLayout(this.keySignTemplate[item]);
      }
    },

    toggleNofitications() {
      this.showCloseNotificationsSidebar();
    },

    goUserPage(item) {
      if (this.productName == "conecta_track") {
        this.$router.push({
          path: "/track/user",
          query: { id: item.id_google },
        });
      } else if (this.productName == "conecta_sign") {
        this.$router.push({
          path: "/sign/users#",
          query: { email: item.email },
        });
      } else if (this.$route.query.id !== item.id_google) {
        this.$router.push({ path: "/user", query: { id: item.id_google } });
      }
    },

    goToindexPath() {
      if (this.$route.path !== this.indexPath) {
        this.$router.push(this.indexPath).catch();
      }
    },

    cancelLogout() {
      this.dialogLogout = false;
    },

    customFilter(item, queryText) {
      const textOne = item.email.toLowerCase().trim();
      const textTwo = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    refreshPage() {
      document.location.reload(true);
    },

    snackbarPurchaseInterest(info) {
      this.$emit("snackbarPurchaseInterest", info);
    },

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from navbar";
      const clicked_from = "Botão de agendar reunião (navbar)";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },
  },
};
</script>

<style scoped>
.trial-products {
  background: rgba(135, 130, 156, 0.548);
}

.link:hover {
  cursor: pointer;
}
</style>
