<template>
  <div>
    <v-row v-if="equalColumns" :class="`ma-0 ${getStyle} rounded-t`">
      <v-col lg="6" sm="12"><slot name="left" /></v-col>
      <v-col lg="6" sm="12"><slot name="right" /></v-col>
    </v-row>
    <v-row
      v-else
      :class="`${
        $vuetify.breakpoint.xs
          ? 'd-flex flex-column'
          : 'd-flex align-center flex-wrap'
      } ma-0 py-0 px-4 ${getStyle} rounded-t`"
    >
      <v-col v-if="isSelectMode && !customLeft" cols="12" :class="`pa-0`">
        <v-row
          dense
          no-gutters
          :class="`${
            $vuetify.breakpoint.xs
              ? 'flex-column justify-start'
              : 'align-center'
          } ${$vuetify.breakpoint.mobile ? 'mb-3' : 'pa-0 ma-0'}`"
        >
          <v-col lg="4" xl="3" sm="6" xs="12" md="4" :class="`ma-0 pa-0`">
            <!-- ÁREA DO BOTÃO DE CANCELAR SELEÇÃO + TÍTULO -->
            <v-row dense no-gutters class="align-center pa-0 mx-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="$emit('reset_selection')"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4"
                    :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.lg"
                  >
                    <v-icon v-text="'ph-x'" />
                  </v-btn>
                </template>
                <span class="caption">Resetar seleção</span>
              </v-tooltip>
              <v-card-title
                v-if="hasTitle"
                :class="`ml-0 px-0 word-break ${
                  $vuetify.breakpoint.xs
                    ? 'text-subtitle-1 font-weight-medium'
                    : ''
                }`"
              >
                {{ title }}
              </v-card-title>
            </v-row>
          </v-col>
          <v-col lg="8" xl="9" sm="4" md="4" xs="12" :class="`ma-0 pa-0`">
            <!-- ÁREA DOS BOTÕES DE AÇÃO EM MASSA -->
            <slot
              name="actions"
              :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="customLeft"
        :lg="hasActionButton ? 10 : 5"
        md="7"
        sm="7"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
        } py-0 px-0`"
      >
        <slot name="left" />
      </v-col>
      <v-col
        v-else-if="!customLeft && !isSelectMode"
        :lg="hasActionButton ? 10 : 5"
        md="7"
        sm="7"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
        } pa-0 my-0`"
      >
        <v-row
          dense
          no-gutters
          :class="`pa-0 ma-0 ${
            $vuetify.breakpoint.xs
              ? 'flex-column justify-start'
              : 'align-center'
          }`"
        >
          <v-col lg="5" xl="4" md="8" class="ma-0 pa-0">
            <!-- ÁREA DO BOTÃO DE CANCELAR SELEÇÃO + TÍTULO -->
            <v-row dense no-gutters class="align-center pa-0 ma-0">
              <v-card-title
                v-if="hasTitle"
                class="my-0 ml-0 px-0 word-break text-no-wrap"
              >
                {{ title }}
              </v-card-title>
              <v-divider
                vertical
                class="my-6 mx-0"
                v-if="!$vuetify.breakpoint.smAndDown && hasSubtitle"
              />
              <v-card-subtitle
                v-if="!$vuetify.breakpoint.smAndDown && hasSubtitle"
                :class="`
                  word-break
                  ${
                    $vuetify.theme.dark
                      ? 'grey--text text--lighten-5'
                      : 'grey--text text--darken-2'
                  }
                  text-body-1
                  ${$vuetify.breakpoint.xs ? 'mx-0 px-2' : ''}
                `"
              >
                {{ subtitle }}
              </v-card-subtitle>
            </v-row>
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            lg="7"
            xl="8"
            md="4"
            :class="`ma-0 pa-0`"
          >
            <!-- ÁREA DOS FILTROS -->
            <slot
              name="actions"
              :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        xs="12"
        class="d-flex align-center py-0 px-0"
        v-if="$vuetify.breakpoint.xs"
      />
      <v-col
        v-if="customRight"
        lg="7"
        md="5"
        sm="5"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? 'py-4' : ''
        } d-flex align-center py-0 px-0`"
      >
        <slot name="right" />
      </v-col>

      <v-col
        v-else-if="!hideActionButton"
        :lg="hasActionButton ? 2 : 7"
        md="5"
        sm="5"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? 'pt-0 pb-4' : ''
        } d-flex align-center pa-0`"
      >
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

        <v-tooltip v-if="actionTooltip" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :dark="disabledAction || loadingAction ? false : true"
              large
              elevation="0"
              :color="actionColor"
              @click="$emit(action)"
              :block="$vuetify.breakpoint.xs"
              :class="`text-none text-body-1 font-weight-medium ${actionClass}`"
              :disabled="disabledAction || loadingAction"
            >
              <v-icon
                v-if="actionIcon"
                v-text="actionIcon"
                left
                size="20"
                :class="`${
                  actionIcon === 'ph-arrows-clockwise' && loadingAction
                    ? 'icon-spinner'
                    : ''
                }`"
              />
              <span> {{ actionText }}</span>
            </v-btn>
          </template>
          <span>{{ actionTooltip }}</span>
        </v-tooltip>
        <v-btn
          v-else
          large
          elevation="0"
          :dark="disabledAction || loadingAction ? false : true"
          :color="actionColor"
          @click="$emit(action)"
          :block="$vuetify.breakpoint.xs"
          :class="`text-none text-body-1 font-weight-medium ${actionClass}`"
          :disabled="disabledAction || loadingAction"
        >
          <v-icon
            v-if="actionIcon"
            v-text="actionIcon"
            left
            size="20"
            :class="`${
              actionIcon === 'ph-arrows-clockwise' && loadingAction
                ? 'icon-spinner'
                : ''
            }`"
          />
          <span> {{ actionText }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="$vuetify.breakpoint.smAndDown && hasActionButton && !customRight"
      class="px-4 pt-0 pb-4 ma-0"
    >
      <!-- ÁREA DOS FILTROS VERSÃO MOBILE-->
      <slot
        name="actions"
        :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
      />
    </v-row>
    <v-divider v-if="!hideDivider" />
  </div>
</template>
<script>
export default {
  name: "TableHeader",
  props: {
    id: { type: String, required: true },
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    action: { type: String, default: "" },
    actionText: { type: String, default: "Adicionar" },
    actionIcon: { type: String, default: "ph-plus" },
    actionColor: { type: String, default: "accent" },
    actionClass: { type: String, default: "accent" },
    actionTooltip: { type: String, default: "" },
    loadingAction: { type: Boolean, default: false },
    disabledAction: { type: Boolean, default: false },
    customLeft: { type: Boolean, default: false },
    customRight: { type: Boolean, default: false },
    equalColumns: { type: Boolean, default: false },
    hideDivider: { type: Boolean, default: false },
    hideActionButton: { type: Boolean, default: false },
    styles: { type: String, default: "" },
    isSelectMode: { type: Boolean, default: false },
    color: { type: String, default: "orange lighten-5" },
  },
  computed: {
    hasTitle() {
      return !!this.title;
    },
    hasSubtitle() {
      return !!this.subtitle;
    },
    hasActionButton() {
      return this.action;
    },
    getStyle() {
      if (this.isSelectMode) {
        if (this.color) {
          return this.$vuetify.theme.dark ? "grey darken-2" : `${this.color}`;
        }
        return this.$vuetify.theme.dark ? "grey darken-2" : "orange lighten-5";
      }
      return this.styles;
    },
  },
};
</script>
