<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            text
            x-large
            class="rounded-pill px-4 text-none text-h6 font-weight-medium secondary--text text--lighten-2"
            v-on="{ ...menu, ...tooltip }"
          >
            <v-skeleton-loader v-if="loading" max-width="300" type="button" />

            <span v-else>
              {{ selectedTemplateName || "Modelo não encontrado" }}
            </span>

            <v-icon v-text="'ph-fill ph-gear'" right />
          </v-btn>
        </template>
        <span>Editar configurações deste modelo</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar color="primary" flat class="font-weight-medium px-0">
        <v-app-bar-nav-icon @click="dialog = false" class="ma-0">
          <v-icon v-text="'ph-x'" />
        </v-app-bar-nav-icon>

        <v-toolbar-title class="px-2">
          Configurações do modelo
        </v-toolbar-title>

        <template v-slot:extension>
          <v-tabs
            v-model="selectedTab"
            slider-color="accent"
            :slider-size="4"
            :hide-slider="loading"
            :disabled="loading"
            class="pl-5"
          >
            <v-tab
              v-for="tab in settingsTabs"
              :key="tab"
              class="text-none text-body-1 font-weight-medium secondary--text"
              :active-class="'accent--text'"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-progress-linear indeterminate color="accent" v-if="loading" />

      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <v-form v-model="isValid" style="width: 100%" class="px-4 py-6">
            <!-- ALERT PARA INDICAR QUE A EDIÇÃO ESTÁ BLOQUEADA PELO MODELO SER PADRÃO -->
            <v-card-text class="ma-0 pa-0" @click.stop="selectedTab = 1">
              <Alert
                v-if="isDefault"
                :color="'error'"
                dense
                :text="false"
                class="mx-0 cursor-pointer"
              >
                <b>Modelo definido como padrão</b>. Para alterar nome e
                descrição, remova-o dos modelos padrões na aba de configurações
                <b>Avançadas</b>.
              </Alert>
            </v-card-text>

            <v-text-field
              v-model="newTemplate.name"
              filled
              label="Nome"
              :disabled="isDefault || loading"
              :rules="[rules.templateName]"
              clearable
            />

            <v-textarea
              v-model="newTemplate.description"
              filled
              label="Descrição"
              :disabled="isDefault || loading"
              :rules="[rules.templateDescription]"
              clearable
            />

            <v-scroll-y-transition>
              <v-card-actions
                v-if="!isDefault && showSaveButton"
                class="justify-center"
              >
                <v-btn
                  large
                  color="primary"
                  class="text-none text-body-1 font-weight-medium px-6"
                  :disabled="!isValid"
                  :loading="loading"
                  @click="updateNameAndDescription"
                >
                  Salvar alterações
                </v-btn>
              </v-card-actions>
            </v-scroll-y-transition>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-row align="start" class="mx-2 my-4 pa-0">
            <v-col cols="1" class="py-0">
              <v-switch
                inset
                hide-details
                disabled
                v-model="templateIsForNewUsers"
                @change="setTemplateToNewUsers"
              />
            </v-col>
            <v-col class="pt-1">
              <v-list-item class="" disabled>
                <v-list-item-content
                  class="cursor-pointer"
                  @click="setTemplateToNewUsers"
                >
                  <v-list-item-title class="font-weight-medium text-body-1">
                    Modelo pré-definido para novos usuários
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-wrap break-word normal-line-height mt-2"
                  >
                    Defina este modelo para ser o utilizado para novas contas de
                    usuários adicionadas ao Workspace.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <!-- DEFINIÇÃO DE MODELO PADRÃO -->

          <div v-if="isConecta">
            <v-divider class="mt-4" />

            <v-subheader class="px-4 mt-3">
              Configurações avançadas
            </v-subheader>

            <v-row align="start" class="ma-2 pa-0">
              <v-col cols="1" class="py-0">
                <v-switch
                  inset
                  hide-details
                  v-model="templateType"
                  :true-value="'default'"
                  :false-value="'custom'"
                  @change="setSignatureType"
                />
              </v-col>
              <v-col class="pt-1">
                <v-list-item class="" :disabled="loading">
                  <v-list-item-content
                    class="cursor-pointer"
                    @click="setSignatureType"
                  >
                    <v-list-item-title class="font-weight-medium text-body-1">
                      Modelo padrão
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-wrap break-word normal-line-height mt-2"
                    >
                      Defina o modelo como público, para que outros usuários
                      possam editar cópias dele.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row align="start" class="mx-2 mb-4 mt-0 pa-0">
              <v-col cols="1" class="py-0">
                <v-switch
                  inset
                  hide-details
                  v-model="signatureIsPopular"
                  :true-value="true"
                  :false-value="false"
                  @change="setPopularSignature"
                />
              </v-col>
              <v-col class="pt-1">
                <v-list-item class="" :disabled="loading">
                  <v-list-item-content
                    class="cursor-pointer"
                    @click="setPopularSignature"
                  >
                    <v-list-item-title class="font-weight-medium text-body-1">
                      Modelo popular
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-wrap break-word normal-line-height mt-2"
                    >
                      Marque como popular para que ele seja exibido com maior
                      visibilidade, atraindo a atenção dos clientes.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>
<script>
import { hasChanges } from "@/helpers/services/utils";
import { rules } from "@/helpers/variables/templates";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditTemplateSettings",

  props: {
    template: { type: Object, required: true },
  },

  data() {
    return {
      dialog: false,
      isValid: true,
      loading: false,
      menuItems: [
        {
          title: "Duplicar modelo",
          action: "duplicate",
        },

        {
          title: "Excluir modelo",
          action: "delete",
        },
        {
          divider: true,
        },
        {
          title: "Obter ajuda",
          action: "get_help",
        },
      ],
      newTemplate: {
        name: "",
        description: "",
      },
      rules,
      selectedTab: 0,
      settingsTabs: ["Gerais", "Avançadas"],
      signatureType: "custom",
      signatureForNewUsers: false,
      signatureIsPopular: false,
    };
  },

  computed: {
    ...mapGetters([
      "isConecta",
      "selectedTemplate",
      "selectedTemplateType",
      "selectedTemplateName",
      "selectedTemplateId",
      "isDefaultForNewUsers",
      "selectedTemplateIsPopular",
    ]),

    isDefault() {
      return this.selectedTemplateType === "default";
    },

    templateId() {
      return this.$route.query.id;
    },

    templateType: {
      get() {
        return this.signatureType;
      },
      set(new_val) {
        this.signatureType = new_val;
      },
    },

    templateIsForNewUsers: {
      get() {
        return this.signatureForNewUsers;
      },
      set(new_val) {
        this.signatureForNewUsers = new_val;
      },
    },

    showSaveButton() {
      const current = {
        name: this.selectedTemplate.name,
        description: this.selectedTemplate.description,
      };
      return hasChanges(this.newTemplate, current);
    },
  },

  watch: {
    selectedTemplateType() {
      this.updateLocalTemplateType();
    },
    selectedTemplateIsPopular() {
      this.updateTemplateIsPopular();
    },

    selectedTemplate() {
      this.setTemporaryTemplate();
    },
  },

  methods: {
    ...mapActions([
      "setSignatureTemplateType",
      "selectedIsDefaultForNewUsers",
      "updateSignatureTemplate",
      "setNewUsersTemplate",
      "setSignaturePopular",
    ]),

    getPayload() {
      if (this.selectedTemplate) {
        let { id, name, description } = this.selectedTemplate;

        return {
          id_template: id,
          name,
          description,
        };
      }
      return false;
    },

    resetTemplateGeneralDetails() {
      this.newTemplate = { name: "", description: "" };
    },

    async setSignatureType() {
      this.loading = true;

      this.setTemporaryTemplateTypeChange();

      await this.setSignatureTemplateType(this.selectedTemplate);

      this.loading = false;
    },

    async setPopularSignature() {
      this.loading = true;

      this.setTemporaryTemplatePopularChange();
      await this.setSignaturePopular(this.selectedTemplate);

      this.loading = false;
    },

    async setTemplateToNewUsers() {
      this.loading = true;
      await this.setNewUsersTemplate(this.selectedTemplateId);
      this.updateLocalTemplateDefaultForNewUsers();
      this.loading = false;
    },

    setTemporaryTemplate() {
      if (this.selectedTemplate) {
        const { name, description } = this.selectedTemplate;
        this.newTemplate = Object.assign({}, { name, description });
      } else {
        this.resetTemplateGeneralDetails();
      }
    },

    setTemporaryTemplateTypeChange() {
      const isGoingToChangeTo = this.isDefault ? "custom" : "default";
      this.templateType = isGoingToChangeTo;
    },

    setTemporaryTemplatePopularChange() {
      const isGoingToChangeTo = !this.selectedTemplate.is_popular;
      this.signatureIsPopular = isGoingToChangeTo;
    },

    updateLocalTemplateType() {
      this.templateType = this.selectedTemplateType;
    },

    updateTemplateIsPopular() {
      this.signatureIsPopular = this.selectedTemplateIsPopular;
    },

    updateLocalTemplateDefaultForNewUsers() {
      this.templateIsForNewUsers = this.selectedIsDefaultForNewUsers;
    },

    async updateNameAndDescription() {
      this.loading = true;

      await this.updateSignatureTemplate({
        id_template: this.selectedTemplateId,
        ...this.newTemplate,
      });

      this.loading = false;
    },
  },

  async mounted() {
    await this.setTemporaryTemplate();
    this.updateLocalTemplateType();
    this.updateTemplateIsPopular();
    // this.updateLocalTemplateDefaultForNewUsers();
  },

  beforeDestroy() {
    this.resetTemplateGeneralDetails();
  },
};
</script>
