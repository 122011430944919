<template>
  <v-menu offset-y open-on-hover close-delay="100" v-if="hasTrialPlans">
    <template v-slot:activator="{ on, attrs }">
      <div
        :class="`d-flex align-center justify-start rounded-pill ${trialBarClass} py-2  ${
          mobile ? 'px-0' : 'mx-2 px-3'
        }`"
        style="max-height: 40px !important"
      >
        <!-- VERSÃO MOBILE (planos lado a lado) -->
        <div
          v-if="mobile && hasTrialPlans"
          class="d-flex align-center"
          style="overflow: hidden"
        >
          <!-- <span class="caption font-weight-bold mr-2"> Testando: </span> -->
          <!-- ÁREA DOS PLANOS -->
          <div v-for="(item, index) in trialPlans" :key="index" class="mr-2">
            <div v-bind="attrs" v-on="on" class="d-flex align-center">
              <span
                @mouseover="selectedPlan = item"
                class="d-flex align-center mt-1"
              >
                <Logo
                  :product="item.plan.short_code"
                  width="38"
                  height="38"
                  noMargins
                  customClass="mr-2"
                />
              </span>
            </div>
          </div>
        </div>
        <!-- VERSÃO DESKTOP: possibilidade de mostrar/ocultar planos + destaque para o plano que contém o produto atual (página atual) -->
        <div v-else-if="hasTrialPlans" class="d-flex align-center">
          <div v-if="featuredPlan" v-bind="attrs" v-on="on">
            <div
              @mouseover="selectedPlan = featuredPlan"
              class="d-flex align-center"
            >
              <!-- featuredPlan = plano em destaque (se estiver na página do Track, vai destacar o plano que tem o track incluso) -->
              <Logo
                :product="
                  featuredPlan.plan.short_code
                    ? featuredPlan.plan.short_code
                    : 'plans'
                "
                width="30"
                height="30"
                noMargins
                customClass="mr-2"
              />
              <!-- Informações do plano em destaque -->
              <div class="d-flex align-center">
                <div class="flex-column mx-2 my-1">
                  <div class="caption font-weight-medium my-0 py-0">
                    Dias restantes
                  </div>
                  <div class="caption my-0 py-0">
                    {{ endTrial(featuredPlan.end_trial) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="d-flex align-center">
            <div class="flex-column mx-2 my-1">
              <div class="caption font-weight-medium my-0 py-0">
                Planos em TRIAL
              </div>
            </div>
          </div>
          <!-- Divisória dos demais planos (se houver) -->
          <v-divider
            class="ml-2 mr-3 my-3"
            style="border-left: 1px solid #fff"
            vertical
            v-if="trialPlans.length > 1 && showOtherPlans && featuredPlan"
          ></v-divider>
          <!-- Demais planos -->
          <div v-if="showOtherPlans" class="d-flex">
            <v-btn
              icon
              class="mx-1"
              small
              rounded
              v-for="(otherPlan, i) in othersPlans"
              :key="i"
              :title="
                otherPlan.plan.name + ' - ' + endTrial(otherPlan.end_trial)
              "
              @click="featuredPlan = otherPlan"
            >
              <!-- Mostra um alerta vermelho se o plano exibido na lista está expirado -->
              <v-badge
                bottom
                color="error"
                small
                dot
                offset-x="8"
                offset-y="8"
                v-if="daysToEndTrial(otherPlan.end_trial) < 0"
              >
                <Logo
                  @mouseover="featuredPlan = otherPlan"
                  :product="
                    otherPlan.plan.short_code
                      ? otherPlan.plan.short_code
                      : 'plans'
                  "
                  width="28"
                  height="28"
                  noMargins
                />
              </v-badge>
              <!-- Não mostra o alerta se o plano trial ainda estiver ativo -->
              <Logo
                v-else
                @mouseover="featuredPlan = otherPlan"
                :product="
                  otherPlan.plan.short_code
                    ? otherPlan.plan.short_code
                    : 'plans'
                "
                width="28"
                height="28"
                noMargins
              />
            </v-btn>
          </div>
          <!-- Botão de ocultar/mostrar demais planos -->
          <v-btn
            v-if="featuredPlan && othersPlans.length > 0"
            icon
            x-small
            class="mx-1"
            @click="showOtherPlans = !showOtherPlans"
            :title="
              showOtherPlans
                ? 'Ocultar outros planos em TRIAL'
                : 'Mostrar outros planos em TRIAL'
            "
          >
            <v-icon
              v-text="showOtherPlans ? 'ph-caret-left' : 'ph-caret-right'"
              color=""
            />
          </v-btn>
        </div>
      </div>
    </template>
    <!-- Exibição dos detalhes do plano -->
    <v-card
      v-if="selectedPlan"
      class="d-flex flex-column justify-center elevation-0"
      width="340"
    >
      <v-card-title class="py-2">
        <div class="d-flex justify-center align-center ma-2 py-0">
          <span class="font-weight-bold text-wrap">{{
            selectedPlan.plan.name
          }}</span>
          <v-chip class="ml-4" x-small>
            {{ selectedPlan.status }}
          </v-chip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <div class="py-4 mx-3 d-flex flex-column">
        <v-card-text class="py-1">
          <span class="font-weight-bold">Dias restantes:</span>
          <span
            class="pl-3"
            v-if="
              daysToEndTrial(selectedPlan.end_trial) === 0 &&
              checkExpiresToday(selectedPlan.end_trial)
            "
          >
            Último dia de trial
          </span>
          <span class="pl-3" v-else>
            {{
              daysToEndTrial(selectedPlan.end_trial) > 0
                ? daysToEndTrial(selectedPlan.end_trial)
                : "Expirado"
            }}
          </span>
        </v-card-text>
        <v-card-text class="py-1">
          <span
            v-if="daysToEndTrial(selectedPlan.end_trial) > 0"
            class="font-weight-bold"
            >Trial termina em:</span
          >
          <span
            v-else-if="
              daysToEndTrial(selectedPlan.end_trial) == 0 &&
              checkExpiresToday(selectedPlan.end_trial)
            "
            class="font-weight-bold"
            >Trial termina hoje:</span
          >
          <span v-else class="font-weight-bold">Trial terminou em:</span>
          <span class="pl-3">{{
            formatDateFullMonth(selectedPlan.end_trial)
          }}</span>
        </v-card-text>
        <v-card-text class="py-1">
          <span class="font-weight-bold">Nº de licenças:</span>
          <span class="pl-3">{{ usersNumber }} </span>
        </v-card-text>
        <v-card-text class="py-1">
          <span class="font-weight-bold">Criado em:</span>
          <span class="pl-3">{{
            formatDateFullMonth(selectedPlan.init_date)
          }}</span>
        </v-card-text>
        <v-card-text class="py-1 font-weight-bold">Produtos:</v-card-text>
        <v-card-actions class="align-start mx-2 flex-wrap">
          <span
            v-for="product in products"
            :key="product"
            style="max-width: 40px"
            class="mr-2"
          >
            <Logo products :product="product" height="30" no-margins />
          </span>
        </v-card-actions>
        <v-btn
          @click="requestActivation(selectedPlan.plan.name)"
          class="mb-3 px-4 mt-5 mx-auto elevation-0 text-none text-body-2 font-weight-medium rounded-pill px-8"
          color="success"
          dark
          large
        >
          <v-icon v-text="'ph-gift'" left />
          Contratar plano
        </v-btn>
        <v-btn
          class="mx-auto mb-2 text-none caption font-weight-medium transparent"
          color="grey darken-2"
          text
          x-small
          :to="{ path: '/company' }"
        >
          Informações da Empresa
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import Logo from "@/components/base/Logo";
import {
  checkExpiresToday,
  formatDate,
  formatDateFullMonth,
} from "@/helpers/services/utils";
import { productNames } from "@/helpers/variables/translateString";
import * as moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: { Logo },
  props: {
    show: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
  },
  data() {
    return {
      showOtherPlans: true,
      selectedPlan: null,
      featuredPlan: null,
      productNames,
    };
  },

  computed: {
    ...mapGetters([
      "productName",
      "trialPlans",
      "hasTrialPlans",
      "usersNumber",
    ]),

    currentlyPlanToShow: {
      get() {
        return this.featuredPlan;
      },
      set(new_value) {
        this.featuredPlan = new_value;
      },
    },

    productsOfCurrentlyPlan() {
      return this.currentlyPlanToShow
        ? this.currentlyPlanToShow.plan.products.map((product) => product.name)
        : [];
    },

    products() {
      return this.selectedPlan.plan.products.map((item) => item.name);
    },

    trialBarClass() {
      if (this.show) {
        if (this.mobile) {
          return "bordered";
        }
        return this.productName !== "conecta_sign"
          ? "trial-products"
          : "trial-products-sign";
      }
      return "";
    },

    actualPlan() {
      return (
        this.trialPlans.find((item) =>
          item.plan.products.find(
            (product) => product.name === this.productName
          )
        ) || null
      );
    },

    othersPlans() {
      return this.trialPlans.filter((plan) => {
        return plan.plan.products.find(
          (product) => !this.productsOfCurrentlyPlan.includes(product.name)
        );
      });
    },
  },

  watch: {
    actualPlan() {
      this.featuredPlan = this.actualPlan ? this.actualPlan : null;
    },
  },

  methods: {
    formatDate,

    formatDateFullMonth,

    checkExpiresToday,

    requestActivation(product) {
      this.$emit("snackbarPurchaseInterest", product);
    },

    daysToEndTrial(day) {
      moment.locale("pt-br");
      const dateEndTrial = moment(day);
      return dateEndTrial.diff(moment().startOf("day"), "days");
    },

    endTrial(time) {
      moment.locale("pt-br");
      const dateEndTrial = moment(time);
      const diffDate = dateEndTrial.diff(moment().startOf("day"), "days");

      if (dateEndTrial.isAfter(moment(), "day")) {
        return `${
          diffDate === 1
            ? diffDate + " dia restante"
            : diffDate + " dias restantes"
        } `;
      } else if (dateEndTrial.isSame(moment(), "day")) {
        return "Último dia de trial!";
      } else {
        return "Expirado";
      }
    },

    planProducts(plan) {
      let products = plan.products.map((item) => productNames[item.name]);
      return products.join(", ");
    },
  },

  beforeMount() {
    this.featuredPlan = this.actualPlan ? this.actualPlan : null;
  },
};
</script>
<style scoped>
.trial-products {
  border: 1px solid rgb(102, 102, 102);
}
.trial-products-sign {
  background: rgba(119, 119, 119, 0.2);
}
</style>
